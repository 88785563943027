import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  INIT_CHECKOUT_ERROR,
  INIT_CHECKOUT_DETAILS,
  INIT_CHECKOUT_SUCCESS,
  PAYMENT_COMPLETED_ERROR,
  PAYMENT_COMPLETED,
  PAYMENT_COMPLETED_SUCCESS,
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_ERROR,
  CREATE_VIRTUALL_ACCOUNT,
  CREATE_VIRTUALL_ACCOUNT_SUCCESS,
} from "../constants/constants";
import CryptoJS from "crypto-js";
import config from "../../config";
import miscService from "../../services/misc.service";
import paymentsService from "../../services/payments.service";
import { resetFloatingData } from "../actions/floating.action";
import {
  processCheckout,
  paymentCompleted as pay,
} from "../actions/paymentcheckout.action";
import orderService from "../../services/order.service";
import logsService from "../../services/logs.service";
import firebase from "firebase/compat/app";


const b64toBlob = (base64, type = "application/octet-stream") =>
  fetch(`${base64}`).then((res) => res.blob());

const execInitCheckoutForm = async (action, profile) => {
  try {
    const { type, transaction_details } = action.data.data;
    var paymentDetails = {
      orderAmount: 0,
      customerEmail: profile.userProfile.email?.trim()
        ? profile.userProfile?.email
        : "vouchtransaction@gmail.com",
    };
    switch (type) {
      case "Gig_Order":
        if (transaction_details.isPayingFull) {
          paymentDetails.orderAmount = transaction_details.total_amount;
        } else {
          paymentDetails.orderAmount =
            transaction_details.milestones[0].milestoneAmount;
        }
        break;
      case "Fund_Milestone":
        {
          var milestoneDetails = await paymentsService.getMilestone({
            id: transaction_details.milestone_id,
          });
          paymentDetails.orderAmount = milestoneDetails.milestoneAmount;
          transaction_details.orderAmount = milestoneDetails.milestoneAmount;
        }
        break;
      case "Fund_Full_Payment":
        {
          var orderDetails = await paymentsService.getGig({
            id: transaction_details.payment_id,
          });
          paymentDetails.orderAmount = orderDetails.total_amount;
          transaction_details.orderAmount = paymentDetails.orderAmount;
        }
        break;
      default:
        throw { message: "Something went wrong" };
    }

    // const creds = await miscService.getCreds();
    // let app_id = CryptoJS.AES.decrypt(creds.vek, creds.vhk).toString(
    //   CryptoJS.enc.Utf8
    // );

    // let app_id = config.rzp_key;

    return {
      type: type,
      paymentDetails: paymentDetails,
      gigDetails: transaction_details,
      user: profile.userProfile,
      app_id: {},
      paymentOptions: action.options,
    };
  } catch (error) {
    // console.log('Key Error', error);
    throw error;
  }
};

const execPaymentCompleted = async (action, state) => {
  try {
    const { type, gigDetails } = state;
    const { data } = action;
    const transactionDetails = data;
    var response = null;
    // console.log(data);
    if (transactionDetails.txStatus == "SUCCESS") {
      switch (type) {
        case "Gig_Order":
          {
            var params = {
              transactionDetails: JSON.stringify(transactionDetails),
              gigDetails: JSON.stringify(gigDetails),
            };
            var formData = new FormData();
            gigDetails.Attachments.forEach(async (item, i) => {
              const b = await b64toBlob(item.uri);
              formData.append("Attachments", b, item.name);
            });
            delete gigDetails.Attachments;
            formData.append(
              "transactionDetails",
              JSON.stringify(transactionDetails)
            );
            formData.append("gigDetails", JSON.stringify(gigDetails));
            response = await paymentsService.createPayment(formData);
            logsService.logCustomEvent("transaction_created", {
              gigCategory: gigDetails.category,
              transactionAmount: gigDetails.total_amount,
              status: gigDetails.order_status,
              noOfMiletones: gigDetails.milestones.length,
              mode: gigDetails.mode,
              requestFrom: gigDetails.request_from,
              toUser: gigDetails.to_user,
            });
          }
          break;
        case "Fund_Full_Payment":
          {
            var params = {
              transactionDetails: JSON.stringify(transactionDetails),
              gigDetails: JSON.stringify(gigDetails),
              type: "Fund_Full_Payment",
            };
            response = await paymentsService.updatePayment(params);
          }
          break;
        case "Fund_Milestone":
          {
            var params = {
              transactionDetails: JSON.stringify(transactionDetails),
              gigDetails: JSON.stringify(gigDetails),
              type: "Fund_Milestone",
            };
            response = await paymentsService.updatePayment(params);
          }
          break;
        default:
          throw { message: "Something went wrong" };
      }

      return response.link;
    } else {
      throw { message: transactionDetails.txMsg };
    }
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

// const execCheckoutPayment = async (action, state) => {
//   try {
//     var paymentOptions = state.paymentOptions;
//     // console.log(state);
//     var options = {
//       description: "Vouch Order",
//       currency: "INR",
//       key_id: state.app_id,
//       amount: parseFloat(
//         (parseFloat(state.paymentDetails.orderAmount) * 100).toFixed(2)
//       ),
//       // order_id: state.paymentDetails.orderId,
//       email: state.user.email.trim()
//         ? state.user.email.trim()
//         : "vouchtransaction@gmail.com",
//       contact: state.user.mobile,
//     };
//     var orderId;
//     switch (paymentOptions.method) {
//       case "UPI":
//         {
//           orderId = await orderService.createOrder({
//             amount: state.paymentDetails.orderAmount,
//           });
//           options.method = "upi";
//           options.vpa = paymentOptions.vpa;
//           options.order_id = orderId.id;
//           await miscService.saveUpiInfo({ upi: paymentOptions.vpa });
//         }
//         break;
//       case "NETBANKING":
//         {
//           var revicedAmount = Math.round(
//             parseInt(state.paymentDetails.orderAmount) +
//               (2 / 100) * parseInt(state.paymentDetails.orderAmount)
//           );
//           orderId = await orderService.createOrder({
//             amount: revicedAmount,
//           });
//           options.order_id = orderId.id;
//           options.method = "netbanking";
//           options.bank = paymentOptions.bank;
//           options.amount = parseFloat(
//             (parseFloat(revicedAmount) * 100).toFixed(2)
//           );
//         }
//         break;
//       case "WALLET":
//         {
//           orderId = await orderService.createOrder({
//             amount: state.paymentDetails.orderAmount,
//           });
//           options.method = "wallet";
//           options.wallet = paymentOptions.wallet;
//           options.order_id = orderId.id;
//         }
//         break;
//       case "CARD":
//         {
//           var revicedAmount = Math.round(
//             parseInt(state.paymentDetails.orderAmount) +
//               (2 / 100) * parseInt(state.paymentDetails.orderAmount)
//           );
//           orderId = await orderService.createOrder({
//             amount: revicedAmount,
//           });
//           options = {
//             description: "Vouch Order",
//             currency: "INR",
//             key_id: state.app_id,
//             amount: parseFloat((parseFloat(revicedAmount) * 100).toFixed(2)),
//             order_id: orderId.id,
//             email: state.user.email.trim()
//               ? state.user.email.trim()
//               : "vouchtransaction@gmail.com",
//             contact: state.user.mobile,
//             method: "card",
//             "card[name]": paymentOptions.values.nameOnCard,
//             "card[number]": paymentOptions.values.cardNumber,
//             "card[cvv]": paymentOptions.values.cvv,
//             "card[expiry_month]": paymentOptions.values.expiry.slice(0, 2),
//             "card[expiry_year]": paymentOptions.values.expiry.slice(-2),
//           };
//         }
//         break;
//       default:
//         throw { message: "Something went wrong" };
//     }
//     const pay = await openRazorpay(options);
//     return { ...pay, txStatus: "SUCCESS" };
//   } catch (error) {
//     logsService.logCustomEvent("payment_error", {
//       userId: firebase.auth().currentUser.uid,
//       description: error.error.description,
//     });
//     throw error;
//   }
// };

const execCheckoutPayment = async (action, state) => {
  try {
    var paymentOptions = state.paymentOptions;
    let options = {
      // appId: keyObj.appId,
      orderId: `VO${Date.now().toString()}`,
      orderAmount: parseInt(state.paymentDetails.orderAmount),
      customerName: `${state.user.firstname} ${state.user.lastname}`,
      customerPhone: state.user.mobile,
      customerEmail: state.user.email.trim()
        ? state.user.email.trim()
        : "vouchtransaction@gmail.com",
      notifyUrl:"https://prod.api.iamvouched.com/v1/cashfree/listen/order/paid",
      orderNote: "Vouch Order",
      orderCurrency: "INR",
    };


    var orderId;
    switch (paymentOptions.method) {
      case "UPI":
        {
          options.paymentOption = "upi";
          options.upi = {};
          options.upi.vpa = paymentOptions.vpa;
          await miscService.saveUpiInfo({ upi: paymentOptions.vpa });
        }
        break;
      case "NETBANKING":
        {
          var revicedAmount = Math.round(
            parseInt(state.paymentDetails.orderAmount) +
              (2 / 100) * parseInt(state.paymentDetails.orderAmount)
          );
          options.paymentOption = "nb";
          options.bank = paymentOptions.bank;
          options.orderAmount = parseFloat(revicedAmount)
        }
        break;
      case "WALLET":
        {
          orderId = await orderService.createOrder({
            amount: state.paymentDetails.orderAmount,
          });
          options.method = "wallet";
          options.wallet = paymentOptions.wallet;
          options.order_id = orderId.id;
        }
        break;
      case "CARD":
        {
          var revicedAmount = Math.round(
            parseInt(state.paymentDetails.orderAmount) +
              (2 / 100) * parseInt(state.paymentDetails.orderAmount)
          );

          options.orderAmount= parseFloat(revicedAmount);
          options.paymentOption = "card";
          options.card = {};
          options.card["holder"] = paymentOptions.values.nameOnCard;
          options.card["number"] = paymentOptions.values.cardNumber;
          options.card["cvv"] = paymentOptions.values.cvv;
          options.card["expiryMonth"] = paymentOptions.values.expiry.slice(0, 2);
          options.card["expiryYear"] = paymentOptions.values.expiry.slice(-4);
        }
        break;
      default:
        throw { message: "Something went wrong" };
    }
    // let signature_data = `appId=${options.appId}&orderId=${options.orderId}&orderAmount=${options.orderAmount}&customerEmail=${options.customerEmail}&customerPhone=${options.customerPhone}&orderCurrency=${options.orderCurrency}`;
    // let signature = CryptoJS.HmacSHA256(
    //   signature_data,
    //   keyObj.sec
    // ).toString(CryptoJS.enc.Base64);
    // options.paymentToken = signature;
    let {cashfree_options} = await miscService.getCashfreeSignature({
      orderId: options.orderId,
      orderAmount: options.orderAmount,
      customerPhone: options.customerPhone,
      customerEmail: options.customerEmail,
      orderCurrency: options.orderCurrency,
    })
    const pay = await openCashfree({...cashfree_options,...options});
    // console.log(pay);
    // return { ...pay, txStatus: "SUCCESS" };
    return { 
      // order_id:pay.response.orderId,
      ...pay,
      txStatus: "SUCCESS" };
  } catch (error) {
    console.log(error);
    logsService.logCustomEvent("payment_error", {
      userId: firebase.auth().currentUser.uid,
      description: error.error.description,
    });
    throw error;
  }
};

export const openCashfree = (options) => {
  return new Promise((resolve, reject) => {
    let conf = {};
    conf.layout = {};
    conf.checkout = "transparent";
    conf.mode = "PROD";
    var response = CashFree.init(conf);
    CashFree.initPopup(); 
    const pay = CashFree.paySeamless(options,
      // postPaymentCallback
      (event)=>{
      if(event.name == "PAYMENT_RESPONSE" && !event.response){
          reject(event);
      }else if (event.name == "PAYMENT_RESPONSE" && event.response.txStatus == "SUCCESS") {
        resolve(event);
      } else if (event.name == "PAYMENT_RESPONSE" && event.response.txStatus == "CANCELLED") {
        // Handle Cancelled
        reject(event);
      } else if (event.name == "PAYMENT_RESPONSE" && event.response.txStatus == "FAILED") {
        // Handle Failed
        reject(event);
      } else if (event.name == "VALIDATION_ERROR") {
        // Incorrect inputs
        reject(event);
      }
    }
    );

  });
};

function* initCheckoutForm(action) {
  try {
    const getItems = (state) => state.userProfile;
    const profile = yield select(getItems);
    const res = yield call(execInitCheckoutForm, action, profile);
    yield put({ type: INIT_CHECKOUT_SUCCESS, response: res });
    yield put(processCheckout());
  } catch (error) {
    // console.log(error);
    yield put({
      type: INIT_CHECKOUT_ERROR,
      response: { message: "Something went wrong please try again later" },
    });
  }
}

function* checkoutPayment(action) {
  try {
    const getItems = (state) => state.paymentcheckout;
    const currentState = yield select(getItems);
    const res = yield call(execCheckoutPayment, action, currentState);
    yield put({ type: CHECKOUT_PAYMENT_SUCCESS });
    yield put(pay(res));
  } catch (error) {
    // console.log(error)
    yield put({
      type: CHECKOUT_PAYMENT_ERROR,
      response: { message: error.message },
    });
  }
}

function* paymentCompleted(action) {
  try {
    const getItems = (state) => state.paymentcheckout;
    const currentState = yield select(getItems);
    const res = yield call(execPaymentCompleted, action, currentState);
    yield put(resetFloatingData());
    yield put({ type: PAYMENT_COMPLETED_SUCCESS, response: { link: res } });
  } catch (error) {
    yield put({
      type: PAYMENT_COMPLETED_ERROR,
      response: { message: error.message },
    });
  }
}

const execCreateVA = async (action) => {
  try {
    var temp;
    if (action.data.type == "Gig_Order") {
      temp = await orderService.createAccount({
        details: action.data.transaction_details,
        isPayingFull: action.data.transaction_details.isPayingFull,
        mode: action.data.type,
      });
    } else {
      temp = await orderService.createAccount({
        details: action.data.transaction_details,
        mode: action.data.type,
      });
    }
    return {
      account_id: temp.accountId,
      gigData: temp.gigData,
      user_id: action.data.hasOwnProperty("to_user")
        ? action.data.to_user
        : action.data.transaction_details.to_user,
      mode: action.data.type,
    };
  } catch (error) {
    // console.log('Payment', error);
    throw error;
  }
};

function* createVA(action) {
  try {
    const res = yield call(execCreateVA, action);
    yield put({ type: CREATE_VIRTUALL_ACCOUNT_SUCCESS, response: res });
  } catch (error) {
    yield put({
      type: CREATE_VIRTUALL_ACCOUNT_ERROR,
      response: { message: "Something went wrong please try again later" },
    });
  }
}

function* watchPaymentCheckout() {
  yield takeLatest(INIT_CHECKOUT_DETAILS, initCheckoutForm);
  yield takeLatest(PAYMENT_COMPLETED, paymentCompleted);
  yield takeLatest(CHECKOUT_PAYMENT, checkoutPayment);
  yield takeLatest(CREATE_VIRTUALL_ACCOUNT, createVA);
}

export default watchPaymentCheckout;
